import * as Paths from '@toolkit/router/paths';
import { AppConfig } from './interfaces';
import { useEffect, useState } from 'react';

export const robotsTag: { type: string } = {
  get type() {
    return this._type;
  },
  set type(value: string) {
    this._type = value;
  },
};

const appConfig: { settings: AppConfig } = {
  get settings() {
    return this._settings;
  },
  set settings(value: AppConfig) {
    this._settings = value;
  },
};

if (window.location.hostname === 'toolkit.phdata.io') {
  appConfig.settings = require('src/config-prod.json');
} else {
  appConfig.settings = require('src/config.json');
}

export function getAppConfig<T extends AppConfig>(): T {
  return appConfig.settings as T;
}

export const skipRedirectUrls: string[] = [Paths.SqlTranslation];

export function useEffectAsync<T>(
  func: () => Promise<T>
): [result: T, loading: boolean] {
  const [result, setResult] = useState<T>(null);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    setLoading(true);
    func()
      .then((value) => setResult(value))
      .finally(() => setLoading(false));
  }, [func]);

  return [result, loading];
}
