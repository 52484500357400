import * as Paths from '../scripts/router/paths';
/* Left Navigation Icons */
import AdvisorToolImg from 'src/resources/advisor-tool-card.png';
import DataGenImg from 'src/resources/data-gen-tool-card.png';
import DataSourceAutomationImg from 'src/resources/data-source-tool-card.png';
import AccessToolImg from 'src/resources/access-tool-card.png';
import ProvisioningAutomationImg from 'src/resources/provision-tool-card.png';
import SQLTranslationImg from 'src/resources/sql-translation-card.png';

import * as regEx from 'Sqlmorph/scripts/constants/regexpattern';
import { camelCase, startCase } from 'lodash';
import { Location } from 'react-router-dom';
import { UpdateTagType } from './enums';
import { CardListProps } from './interfaces';
import { useAuth0 } from '@auth0/auth0-react';

export const appList: CardListProps[] = [
  {
    title: 'Advisor Tool',
    image: AdvisorToolImg,
    desc: 'Quickly and easily identify opportunities to improve the configuration, security, performance, and efficiency of your Snowflake environment.',
    link: Paths.AdvisorTool,
  },
  {
    title: 'Provision Tool',
    image: ProvisioningAutomationImg,
    desc: 'Manage Snowflake resource lifecycles — creating, updating, and destroying — with a templated approach to provide a well-structured information architecture.',
    link: Paths.ProvisionTool,
  },
  {
    title: 'Access Tool',
    image: AccessToolImg,
    desc: 'Powerful visualization and reporting that enables Snowflake data access, auditing, and troubleshooting.',
    link: Paths.AccessTool,
  },
  {
    title: 'Data Source Tool',
    image: DataSourceAutomationImg,
    desc: 'A multipurpose tool that collects, compares, analyzes, and acts on data source metadata and profile metrics.',
    link: Paths.DataSourceTool,
  },
  {
    title: 'SQL Translation',
    image: SQLTranslationImg,
    desc: 'Instantly translate SQL from one language to another, eliminating a usually time-consuming, error-prone, and highly manual process.',
    link: Paths.SqlTranslation,
  },
  {
    title: 'Data Generation Tool',
    image: DataGenImg,
    desc: 'Makes it easy to generate realistic synthetic data for testing, development, and demonstrations.',
    link: Paths.DataGenerationTool,
  },
];

export const updateTagColor: { [key: string]: string } = {
  [UpdateTagType.ANNOUNCEMENTS]: '#FFD963',
  [UpdateTagType.FEATURES]: '#0EB4A7',
  [UpdateTagType.DEPRECATIONS]: '#FD4E57',
  [UpdateTagType.CHANGES]: '#FFD963',
  [UpdateTagType.FIXES]: '#83ADC6',
  [UpdateTagType.ISSUES]: '#FD4E57',
  [UpdateTagType.SECURITY]: '#294C76',
};

export const supportRequestType: string[] = [
  'Advisor Tool',
  'Provision Tool',
  'Data Source Tool',
  'SQL Translation',
  'Analytics Library',
  'Platform Monitoring',
  'Toolkit Account',
  'Sales / Pricing',
  'Other',
];

export const pascalCase = (str) => startCase(camelCase(str));

export function removeTextWrap(text: string): string {
  return (
    (text &&
      text
        .split(regEx.spaceRegEx)
        .filter((v) => v !== '')
        .join(' ')) ||
    ''
  );
}

/**
 * @description To redirect old url into latest url
 * @param location react-router-dom - location
 * @returns return new path url or null
 */

export function redirectURLs(location: Location): string | null {
  const legacyPaths: { [path: string]: string } = {
    general: '',
    apps: 'tools',
    resources: '',
    documentation: 'docs',
    'toolkit-cli/ds/ds': 'data-source',
    'toolkit-cli/provision/provision': 'provision',
    'toolkit-cli/sql/sql': 'sql-translation#sql-tool',
  };
  if (location.hash.includes('#/')) {
    /* Redirect from old hash route paths */
    return location.hash.replace('#', '');
  } else {
    /* While user click on old bookmark url, redirect to latest url */
    let redirectPath: string = location.pathname.replace(/\/home$/, '');
    let doRedirect = false;
    for (const path in legacyPaths) {
      if (redirectPath.includes(path)) {
        doRedirect = true;
        redirectPath = redirectPath.replace(path, legacyPaths[path]);
      }
    }
    if (doRedirect) {
      /* multiple consecutive slashes replace with a single slash */
      redirectPath = redirectPath.replace(/(?<!:)\/\/+/g, '/');
      return location.hash
        ? `${redirectPath}${location.hash}${location.search}`
        : `${redirectPath}${location.search}`;
    } else {
      return null;
    }
  }
}

/**
 * Takes in a hex code and float and returns rgba string
 *
 * @param hex - string hexadecimal color
 * @param alpha - number alpha
 * @returns - return string
 */
export function getRgba(hex: string, alpha: number): string {
  const r = parseInt(hex.slice(1, 3), 16),
    g = parseInt(hex.slice(3, 5), 16),
    b = parseInt(hex.slice(5, 7), 16);

  if (alpha) {
    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
  } else {
    return `rgba(${r}, ${g}, ${b})`;
  }
}

export function isPhdataUser(): boolean {
  const auth0 = useAuth0();
  return auth0.isAuthenticated && auth0.user?.email?.endsWith('@phdata.io');
}
