import { ImageItem } from 'Common/interfaces';
import GraphImg from 'src/resources/ui-screenshots/access-tool/access-graph.png';
import TableImg from 'src/resources/ui-screenshots/access-tool/access-table.png';

export const ImageArray: Array<ImageItem> = [
  {
    img: GraphImg,
    title: 'Graph view',
    rows: 2,
    cols: 4,
    reportPath:
      '/toolkit/docs/access/access-report.html#/?hideAllFilters=Show%20all&tutorialStatus=running',
  },
  {
    img: TableImg,
    title: 'Table view',
    rows: 2,
    cols: 4,
    reportPath:
      '/toolkit/docs/access/access-report.html#/?hideAllFilters=Show%20all&tutorialStatus=running&viewMethod=graph',
  },
];
