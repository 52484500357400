import { CommonContext } from 'Common/common-ui';
import { LoadingIndicator } from '@toolkit/common';
import { setDashboards } from 'Common/common-ui/app-state/actions';
import {
  AnalyticsLibraryProps as ALProps,
  FilterSections,
} from 'Common/interfaces';
import { localStorageService } from 'Common/localStorage';
import { getDashboards } from 'Common/services/api';
import React from 'react';
import { useLoaderData } from 'react-router-dom';
import CardGrid from 'Common/components/CardGrid';

const Dashboards: React.FC = () => {
  const { dispatch } = React.useContext(CommonContext);
  const data = useLoaderData() as { dashboards: ALProps[] };
  const [searchText, setSearchText] = React.useState<string | null>(null);
  const [allDashboards, setAllDashboards] = React.useState<ALProps[]>([]);
  const [filteredDashboards, setFilteredDashboards] = React.useState<ALProps[]>(
    []
  );
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [sections, setSections] = React.useState<FilterSections>(null);

  const selectedTechnologies: string[] = React.useMemo(() => {
    if (sections) {
      return Object.keys(sections.technologies).filter(
        (tech: string) => sections.technologies[tech].on
      );
    }
    return [];
  }, [sections]);

  const selectedTags: string[] = React.useMemo(() => {
    if (sections) {
      return Object.keys(sections.tags).filter(
        (tag: string) => sections.tags[tag].on
      );
    }
    return [];
  }, [sections]);

  React.useEffect(() => {
    setIsLoading(true);
    const loadDatas = async () => {
      const sectionsObj: FilterSections = {
        technologies: {},
        tags: {},
      };

      const dashDatas: ALProps[] = data.dashboards;

      if (dashDatas?.length) {
        dispatch(setDashboards(dashDatas));
        dashDatas.forEach((dashboard: ALProps) => {
          if (sectionsObj.technologies[dashboard.technology]) {
            sectionsObj.technologies[dashboard.technology].count += 1;
          } else {
            sectionsObj.technologies[dashboard.technology] = {
              on: false,
              count: 1,
            };
          }
          dashboard.labels.forEach((label) => {
            if (sectionsObj.tags[label]) {
              sectionsObj.tags[label].count += 1;
            } else {
              sectionsObj.tags[label] = { on: false, count: 1 };
            }
          });
        });
        setSections(sectionsObj);
        setAllDashboards([...dashDatas] as ALProps[]);
        const searchStr: string =
          await localStorageService.getAnalyticsSearchText();
        setSearchText(searchStr as string);
        setIsLoading(false);
      }
    };
    loadDatas();
  }, []);

  React.useEffect(() => {
    let dashboardList: ALProps[] = allDashboards;
    if (selectedTechnologies.length) {
      dashboardList = dashboardList.filter((dashboard) => {
        return sections.technologies[dashboard.technology].on;
      });
    }
    if (selectedTags.length) {
      dashboardList = dashboardList.filter((dashboard) => {
        let shouldInclude = false;
        dashboard.labels.forEach((label) => {
          if (sections.tags[label].on) {
            shouldInclude = true;
          }
        });
        return shouldInclude;
      });
    }
    if (searchText && searchText.trim()) {
      dashboardList =
        dashboardList &&
        dashboardList.filter(
          (dashboard: ALProps) =>
            searchText &&
            (
              dashboard.title?.toLowerCase() +
              dashboard.labels?.join(' ')?.toLowerCase() +
              dashboard.description?.toLowerCase() +
              dashboard.author?.toLowerCase() +
              dashboard.vizUrl?.toLowerCase()
            ).includes(searchText?.toLowerCase())
        );
    }
    setFilteredDashboards([...dashboardList] as ALProps[]);
  }, [allDashboards, sections, searchText]);

  if (isLoading) {
    return (
      <LoadingIndicator
        fullScreen={true}
        title="Loading analytics library..."
      />
    );
  }

  return (
    <CardGrid
      filteredDashboards={filteredDashboards}
      sections={sections}
      setSections={setSections}
    />
  );
};
export default Dashboards;

export function dashboardLoader(): Promise<{ dashboards: ALProps[] }> {
  return getDashboards();
}
