import { Box } from '@mui/material';
import React, { ReactNode, useContext } from 'react';
import {
  Link,
  Location,
  NavigateFunction,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import { LabMetadataListResponse } from 'src/scripts/content/tools/labs/Models';
import { RedirectLoginOptions, useAuth0 } from '@auth0/auth0-react';
import { LoadingIndicator } from '@toolkit/common';
import { CommonContext } from 'Common/common-ui';
import { basePath, getRequest } from 'Common/services/api';
import { useQuery } from 'react-query';
import Typography from '@mui/material/Typography';
import { isPhdataUser } from 'Common/util';
import Grid from '@mui/material/Grid2';
import LabCard from 'src/scripts/content/tools/labs/LabCard';
import PageHeader from 'Common/components/PageHeader';

async function fetchLabsMetadata(): Promise<LabMetadataListResponse> {
  return await getRequest(`${basePath}/labs`);
}

export default function Labs(): ReactNode {
  const navigate: NavigateFunction = useNavigate();
  const { isAuthenticated, loginWithRedirect } = useAuth0();
  const location: Location = useLocation();

  const {
    state: { isInterceptorReady },
  } = useContext(CommonContext);

  // todo this should be moved to a common guard component, along with the one in ToolAccess.tsx
  if (!isAuthenticated) {
    const options: RedirectLoginOptions = {
      appState: { returnTo: location.pathname },
    };
    loginWithRedirect(options);
    return (
      <LoadingIndicator
        fullScreen={true}
        title="Redirecting to login page..."
      />
    );
  } else if (!isPhdataUser()) {
    navigate('/');
  }

  const {
    isLoading,
    data: labsMetadata,
    error: metadataFetchError,
    refetch,
  } = useQuery('fetchLabsMetadata', fetchLabsMetadata, { enabled: false });

  React.useEffect(() => {
    if (isInterceptorReady) {
      refetch();
    }
  }, [refetch, isInterceptorReady]);

  if (metadataFetchError) {
    return (
      <Box sx={{ my: 2 }}>
        <Typography color="red">
          Failed to load labs. Try again later.
        </Typography>
      </Box>
    );
  }

  function body(): ReactNode {
    if (isLoading || !labsMetadata) {
      return <LoadingIndicator fullScreen={true} title="Fetching labs..." />;
    } else {
      return (
        <Grid container alignItems="stretch" spacing={2} m={3} p={1} pb={3}>
          {labsMetadata?.labs?.map((lab) => (
            <Grid
              key={lab.name}
              size={{
                xs: 12,
                md: 6,
                xl: 4,
              }}
            >
              <LabCard lab={lab} />
            </Grid>
          ))}
        </Grid>
      );
    }
  }

  return (
    <Box
      sx={{
        height: '100%',
      }}
    >
      <PageHeader
        header="phData Labs"
        subHeader="Incubating Innovative Solutions"
        description={
          <Typography>
            Slack channel:{' '}
            <Link
              to="https://app.slack.com/client/T13E00KGD/C08HF379MTJ"
              target="_blank"
              rel="noreferrer"
              style={{ color: 'white' }}
            >
              #phdata-labs
            </Link>
          </Typography>
        }
        height={200}
      />
      {body()}
    </Box>
  );
}
