import React from 'react';
import { Helmet } from 'react-helmet';
import { Location, useLocation } from 'react-router-dom';
import { CommonContext } from 'Common/common-ui';
import { setMetaTagTitle } from 'Common/common-ui/app-state/actions';
import { startCase } from 'lodash';

const MeaningFullTitles = {
  access: 'Access Tool',
  advisor: 'Advisor Tool',
  'data-generation': 'Data Generation Tool',
  'data-source': 'Data Source Tool',
  provision: 'Provision Tool',
  sql: 'SQL Tool',
  docs: 'Documentation',
};

const UpdateMetaDescription = () => {
  const {
    state: { metaTagTitle },
    dispatch,
  } = React.useContext(CommonContext);
  const location: Location = useLocation();
  const searchParams: URLSearchParams = new URLSearchParams(location.search);
  const year: string = searchParams.get('year');

  React.useEffect(() => {
    const pathName = location.pathname;
    const _locations = pathName.split('/').filter((v) => v !== '');
    dispatch(setMetaTagTitle(null));
    const metaTitle = updateMetaContent(_locations);
    setTimeout(() => {
      dispatch(setMetaTagTitle(metaTitle));
    }, 100);
  }, [location?.pathname, location?.search]);

  function updateMetaContent(locations: string[]): string {
    let title = 'phData Toolkit';
    if (locations.length) {
      const headers = locations
        .filter((loc) => loc !== 'tools')
        .map((loc: string) => {
          if (loc === 'updates') {
            const update = startCase(loc);
            return year ? `${year} | ${update}` : `Latest | ${update}`;
          }
          return startCase(
            MeaningFullTitles[loc] ? MeaningFullTitles[loc] : loc
          );
        })
        .reverse();
      title = headers.join(' | ');
    }
    return title;
  }

  return (
    <>
      {metaTagTitle && (
        <Helmet>
          <meta
            name="description"
            content={`See details about the ${metaTagTitle?.replace(
              /\//,
              'in'
            )}`}
          />
          <title>{metaTagTitle}</title>
        </Helmet>
      )}
    </>
  );
};

export default UpdateMetaDescription;
