import * as React from 'react';
import ToolsWrapper from '../../ToolsWrapper';
import * as Paths from '@toolkit/router/paths';
import AboutToolWrapper from '../../AboutToolWrapper';
import { Box } from '@mui/material';
import ImageGrid from 'Common/components/ImageGrid';
import { ImageArray } from '../ImageGridArray';
import { ReactNode } from 'react';

export default function About(props: { pageHeader: ReactNode }) {
  return (
    <AboutToolWrapper
      pageHeader={props.pageHeader}
      leftChild={<ToolsWrapper pathName={Paths.ProvisionTool} />}
      rightChild={
        <Box sx={{ pl: 3 }}>
          <ImageGrid
            imageItems={ImageArray}
            rowHeight={175}
            cols={4}
            width={'95%'}
            height={'auto'}
          />
        </Box>
      }
      dataTestId={'provision-tool'}
    />
  );
}
