import * as React from 'react';
import { ReactNode } from 'react';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid2';

export interface AboutToolWrapperProps {
  pageHeader: ReactNode;
  leftChild: JSX.Element;
  rightChild: JSX.Element;
  dataTestId: string;
}

export default function AboutToolWrapper(props: AboutToolWrapperProps) {
  const { pageHeader, leftChild, rightChild, dataTestId } = props;

  return (
    <Container disableGutters={true} maxWidth={false}>
      <Grid container data-testid={dataTestId}>
        <Grid size={{ xs: 12 }}>{pageHeader}</Grid>
        <Grid sx={{ px: 3, py: 3 }}>
          <Grid container>
            <Grid size={{ sm: 12, md: 6 }}>{leftChild}</Grid>
            <Grid size={{ sm: 12, md: 6 }} mb={3}>
              {rightChild}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container>
  );
}
