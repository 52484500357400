import { RedirectLoginOptions, useAuth0 } from '@auth0/auth0-react';
import LoginIcon from '@mui/icons-material/Login';
import { Alert, Box, Divider, Grid2 as Grid } from '@mui/material';
import { BrandColors } from 'Common/common-ui/common/theme';
import { CopyText } from 'Common/enums';
import * as React from 'react';
import { useEffect } from 'react';
import { Location, useLocation } from 'react-router-dom';
import {
  setFieldValues,
  setFormFields,
  setGroup,
  setSql,
  setTemplate,
} from '../contexts/actions';
import { useProvisionDemoContext } from '../contexts/reducer';
import CodeBlock from './CodeBlock';
import DemoHeader from './DemoHeader';
import DynamicForm from './DynamicForm';
import OverviewText from './OverviewText';
import { Experience } from './domain';
import { PROVISION_FORMS } from './forms';
import TealLink from 'Common/components/TealLink';

const ProvisionDemo: React.FC = () => {
  const location: Location = useLocation();
  const { isAuthenticated, loginWithRedirect } = useAuth0();
  const [copyTextSql, setCopyTextSql] = React.useState<CopyText>(
    CopyText.CopyToClipBoard
  );
  const [copyTextTemplate, setCopyTextTemplate] = React.useState<CopyText>(
    CopyText.CopyToClipBoard
  );
  const [copyTextGroup, setCopyTextGroup] = React.useState<CopyText>(
    CopyText.CopyToClipBoard
  );

  const {
    state: { sql, template, experience, requestType, group },
    dispatch,
  } = useProvisionDemoContext();

  useEffect(() => {
    clearFormValues();
  }, [requestType]);

  function clearFormValues(): void {
    const newFieldValues: { [key: string]: string } = {};
    PROVISION_FORMS[requestType].fields.forEach((f) => {
      if (f.type === 'picker' && f.value) {
        newFieldValues[f.name] = f.value[0];
      } else if (f.type === 'number' && f.value) {
        newFieldValues[f.name] = String(f.value);
      }
    });
    dispatch(setSql(''));
    dispatch(setGroup(''));
    dispatch(setTemplate(''));
    dispatch(setFieldValues(newFieldValues));
    dispatch(
      setFormFields(
        PROVISION_FORMS[requestType].fields.map((f) => {
          return { ...f };
        })
      )
    );
    dispatch(setGroup(''));
  }

  async function signIn(e: React.MouseEvent) {
    e.preventDefault();
    const options: RedirectLoginOptions = {
      appState: { returnTo: removeDemoPath(location.pathname) },
    };
    await loginWithRedirect(options);
  }
  function removeDemoPath(url): string {
    return url.replace(/\/demo\//, '/');
  }

  return isAuthenticated ? (
    <Box>
      <DemoHeader />
      <Divider sx={{ my: 2 }} />
      <Grid container spacing={2}>
        <Grid
          minHeight={300}
          size={{
            xs: 12,
            sm: 12,
            md: 6,
          }}
        >
          {experience === Experience.USER ? (
            <DynamicForm />
          ) : (
            <CodeBlock
              copyText={copyTextTemplate}
              setCopyText={setCopyTextTemplate}
              code={
                template?.length
                  ? template
                  : '/* Submit form to generate template */'
              }
              language={template?.length ? 'yaml' : 'json'}
              sectionTitle={'Template'}
            />
          )}
        </Grid>
        <Grid
          minHeight={300}
          size={{
            xs: 12,
            sm: 12,
            md: 6,
          }}
        >
          <CodeBlock
            copyText={
              experience === Experience.USER ? copyTextSql : copyTextGroup
            }
            setCopyText={
              experience === Experience.USER ? setCopyTextSql : setCopyTextGroup
            }
            code={
              experience === Experience.USER
                ? sql?.length
                  ? sql
                  : '/* Submit form to generate SQL */'
                : group?.length
                  ? group
                  : '/* Submit form to generate group file */'
            }
            language={
              experience === Experience.USER
                ? 'sql'
                : group?.length
                  ? 'yaml'
                  : 'json'
            }
            sectionTitle={
              experience === Experience.USER ? 'Generated SQL' : 'Group'
            }
          />
        </Grid>
        <Grid
          my={2}
          size={{
            xs: 12,
          }}
        >
          <Divider sx={{ mb: 2 }} />
          <OverviewText />
        </Grid>
      </Grid>
    </Box>
  ) : (
    <Box my={3}>
      <Alert
        sx={{
          backgroundColor: BrandColors.LightGray,
          color: BrandColors.Primary,
          padding: 3,
        }}
        icon={<LoginIcon sx={{ color: BrandColors.Primary }} />}
      >
        Please{' '}
        <TealLink
          sx={{ fontWeight: 'bolder', cursor: 'pointer' }}
          onClick={signIn}
        >
          sign in
        </TealLink>{' '}
        to view the Provision Tool demo.
      </Alert>
    </Box>
  );
};

export default ProvisionDemo;
