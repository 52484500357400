export enum Practice {
  DE = 'Data Engineering',
  ML = 'AI & Machine Learning',
  EO = 'Elastic Operations',
  STRATEGY = 'Data & AI Strategy',
  ANALYTICS = 'Analytics & Visualization',
  /** Sure, PE is technically not a service, but we may treat some internal incubators as labs. */
  PE = 'Product Engineering',
}

export enum Status {
  ACTIVE = 'Active',
  ARCHIVED = 'Archived',
}

export enum MaturityLevel {
  CONCEPT = 'Concept',
  BRONZE = 'Bronze',
  SILVER = 'Silver',
  GOLD = 'Gold',
  GRADUATED = 'Graduated',
}

export const maturityLevelToDescription = new Map<MaturityLevel, string>([
  [
    MaturityLevel.CONCEPT,
    "The solution design and approach have been approved, but the initial phase of development is not completed, and there isn't any baseline functionality.",
  ],
  [
    MaturityLevel.BRONZE,
    'The project has minimal functionality and tests. It may not have been used in a customer environment yet.',
  ],
  [
    MaturityLevel.SILVER,
    'The project has basic to moderate functionality and tests. It has been used in at least 3 different customer environments.',
  ],
  [
    MaturityLevel.GOLD,
    'The project has enhanced functionality and solid testing. It has been used in at least 10 customer environments. These are prime candidates for the PE team to take ownership of.',
  ],
  [
    MaturityLevel.GRADUATED,
    'Over years of refinement and use with customers, this project has graduated to a PE-owned, product-grade, accelerator.',
  ],
]);

export interface ProjectMetadata {
  name: string;
  description: string;
  origin: Practice;
  owners?: string[];
  status?: Status;
  maturityLevel?: MaturityLevel;
  history?: Event[];
  technologies?: Set<string>;
  practices?: Set<Practice>;
  repositoryUrl?: string;
  gitSshUrl?: string;
}

export interface LabMetadataListResponse {
  labs: ProjectMetadata[];
}
