import * as React from 'react';
import Box from '@mui/material/Box';
import { BrandColors } from 'Common/common-ui/common/theme';
import Stack from '@mui/material/Stack';
import { ReactNode } from 'react';

export default function SearchEndIcon(): ReactNode {
  function getKeySymbol() {
    if (window.navigator.userAgent.indexOf('Mac OS') !== -1) {
      return '⌘';
    } else {
      return 'ctrl';
    }
  }

  const style = {
    maxHeight: 28,
    minWidth: 24,
    fontSize: '14px !important',
    textAlign: 'center',
    backgroundColor: BrandColors.LightGray,
    borderRadius: 1,
  };

  return (
    <Stack direction="row" spacing={1}>
      <Box sx={style}>{getKeySymbol()}</Box>
      <Box sx={style}>K</Box>
    </Stack>
  );
}
