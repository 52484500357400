import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import {
  Box,
  FormControl,
  Grid2 as Grid,
  MenuItem,
  Tooltip,
  Typography,
} from '@mui/material';
import Select from '@mui/material/Select';
import * as Paths from '@toolkit/router/paths';
import HelpIconPopover from 'Common/components/HelpIconPopover';
import * as React from 'react';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { ProvisionTool as ProvisionToolPath } from 'src/scripts/router/paths';
import { setRequestType, setSql } from '../contexts/actions';
import { useProvisionDemoContext } from '../contexts/reducer';
import ToggleExperience from './ToggleExperience';
import { RequestType } from './domain';
import TealButton from 'Common/components/TealButton';
import TealLink from 'Common/components/TealLink';

export default function DemoHeader() {
  const {
    state: { requestType },
    dispatch,
  } = useProvisionDemoContext();
  const navigate: NavigateFunction = useNavigate();

  const handleChange = (event: any) => {
    dispatch(setRequestType(event.target.value));
    dispatch(setSql(''));
  };

  function handleToggleDemoMode(): void {
    navigate(`${ProvisionToolPath}/about`);
  }

  return (
    <Grid container spacing={2} mb={2}>
      <Grid
        size={{
          xs: 12,
          sm: 12,
          md: 6,
        }}
      >
        <Typography variant="h3" mb={1}>
          Provisioning Request Type
        </Typography>

        <Box
          display={'flex'}
          alignItems={'center'}
          justifyContent={'space-between'}
        >
          <FormControl fullWidth sx={{ marginRight: 1 }}>
            <Select
              labelId="demo-select-label"
              id="demo-select"
              sx={{ '& .MuiSelect-select ': { p: 1.5 } }}
              value={requestType}
              onChange={handleChange}
            >
              {Object.values(RequestType).map(
                (requestTypeForm: RequestType, index: number) => (
                  <MenuItem key={index} value={requestTypeForm}>
                    {requestTypeForm}
                  </MenuItem>
                )
              )}
            </Select>
          </FormControl>
          <Box display={'flex'} alignItems="center">
            <ToggleExperience />
            <HelpIconPopover
              tooltipProps={{
                title:
                  'Click for more info on user experience vs ops experience.',
              }}
              content={
                <Box maxWidth={400}>
                  <Box>
                    <Typography
                      pb={0.5}
                      fontWeight={'bolder'}
                      fontSize={'small'}
                    >
                      Ops Experience
                    </Typography>{' '}
                    <Typography fontSize={'small'}>
                      The ops experience displays a{' '}
                      <TealLink
                        to={`${Paths.Documentation}/provision#template-file-format`}
                      >
                        template file
                      </TealLink>{' '}
                      on the left and a{' '}
                      <TealLink
                        to={`${Paths.Documentation}/provision#group-file-format`}
                      >
                        group file
                      </TealLink>{' '}
                      on the right. Template files define the resources to be
                      provisioned, and support variable placeholders and
                      conditional logic using the Jinja2 template engine. The
                      input of each entry in a group fills the parameters in the
                      template.
                    </Typography>
                  </Box>
                  <Box my={2}>
                    <Typography
                      pb={0.5}
                      fontWeight={'bolder'}
                      fontSize={'small'}
                    >
                      User Experience
                    </Typography>{' '}
                    <Typography fontSize={'small'}>
                      The user experience displays an example ITSM submission
                      form on the left, and the SQL statements needed to fulfill
                      that request on the right. The tool converts the templated
                      configuration into the set of SQL commands then executes
                      them against the target Snowflake environment.
                    </Typography>
                  </Box>
                  <Box my={2}>
                    <Typography fontSize={'small'}>
                      Learn more about the provision tool{' '}
                      <TealLink to={`${Paths.Documentation}/provision`}>
                        here.
                      </TealLink>{' '}
                    </Typography>
                  </Box>
                </Box>
              }
            />
          </Box>
        </Box>
      </Grid>
      <Grid
        sx={{
          display: 'flex',
          alignItems: 'end',
          justifyContent: 'end',
        }}
        size={{
          xs: 12,
          sm: 12,
          md: 6,
        }}
      >
        <Tooltip title="Back to Provision Tool Overview">
          <TealButton
            startIcon={<ExitToAppIcon fontSize="small" />}
            variant="contained"
            onClick={handleToggleDemoMode}
          >
            Exit Demo
          </TealButton>
        </Tooltip>
      </Grid>
    </Grid>
  );
}
