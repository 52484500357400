import SynchronizedRefresh from 'AnalyticsLibrary/technologies/tableau/extensions/synchronized-refresh/SynchronizedRefresh';
import { AppBase } from 'Common/common-ui';
import { FeatureFlagsResponse } from 'Common/interfaces';
import { getFeatureFlags } from 'Common/services/api';
import * as React from 'react';
import { useQuery } from 'react-query';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Outlet,
  Route,
  RouterProvider,
  useOutletContext,
} from 'react-router-dom';
import PageNotFound from '../../common/components/pageNotFound';
import App from '../app';
import { NavOption, navOptions as allNavOptions, SubNavItem } from '../content';
import Home from '../content/general/Home';
import SignUp from '../content/general/SignUp';
import * as Paths from './paths';
import { ReactNode } from 'react';

type ContextType = {
  featureFlags: FeatureFlagsResponse | null;
  navOptions?: NavOption;
};

export const childRoutes: string[] = [
  Paths.AccessTool,
  Paths.SqlTranslation,
  Paths.AnalyticsLibrary,
  Paths.Documentation,
  Paths.ProvisionTool,
];

export default function AppRouter(): ReactNode {
  const [navOptions, setNavOptions] = React.useState<NavOption>(allNavOptions);
  const [optionItems, setOptionItems] = React.useState<string[]>([]);
  const { data: featureFlags, isSuccess } = useQuery(
    'features',
    getFeatureFlags
  );

  React.useEffect(() => {
    if (isSuccess) {
      const tempNavOptions: NavOption = { ...navOptions };
      for (const [key, value] of Object.entries(tempNavOptions as NavOption)) {
        tempNavOptions[key].children = value.children.filter(
          (feature: SubNavItem) =>
            !(
              feature.featureFlag in featureFlags &&
              !featureFlags[feature.featureFlag]
            )
        );
      }
      setNavOptions(tempNavOptions);
      setOptionItems(Object.keys(tempNavOptions));
    }
  }, [featureFlags, isSuccess]);

  function generateRoutePath(route: string): string {
    return childRoutes.includes(route) ? `${route}/*` : route;
  }

  function generateRouters() {
    return createBrowserRouter(
      createRoutesFromElements(
        <Route
          path={Paths.Home}
          element={
            <AppBase>
              <Outlet context={{ featureFlags, navOptions }} />
            </AppBase>
          }
        >
          <Route element={<App />}>
            <Route index element={<Home />} />
            {optionItems.map((option: string) =>
              navOptions[option].children.map((subRoute: SubNavItem) => {
                if (subRoute.children) {
                  return (
                    <Route
                      key={subRoute.title}
                      path={generateRoutePath(subRoute.to)}
                      element={subRoute.component}
                      loader={subRoute.loader}
                      action={subRoute.action}
                      shouldRevalidate={subRoute.shouldRevalidate}
                    >
                      {subRoute.children}
                    </Route>
                  );
                } else {
                  return (
                    <Route
                      key={subRoute.title}
                      path={generateRoutePath(subRoute.to)}
                      element={subRoute.component}
                      loader={subRoute.loader}
                      action={subRoute.action}
                      shouldRevalidate={subRoute.shouldRevalidate}
                    />
                  );
                }
              })
            )}
            <Route path={Paths.SignUp} element={<SignUp />} />
            <Route path={Paths.AllPaths} element={<PageNotFound />} />
          </Route>
          <Route path={Paths.Health} element={<>HEALTHY</>} />
          <Route
            path={`${Paths.TableauExtensions}/*`}
            element={<SynchronizedRefresh />}
          />
        </Route>
      )
    );
  }

  return (
    <>
      {isSuccess && navOptions && <RouterProvider router={generateRouters()} />}
    </>
  );
}

export function getBaseOutletContext() {
  return useOutletContext<ContextType>();
}
