import Container from '@mui/material/Container';
import { getBaseOutletContext } from '@toolkit/router/AppRouter';
import { ErrorBoundary } from 'Common/common-ui';
import { logFrontendError } from 'Common/services/api';
import * as React from 'react';
import { Outlet, Route } from 'react-router-dom';
import ResourcePageWrapper from '../../ResourcePageWrapper';
import About from './components/About';
import ProvisionHeader from './components/ProvisionHeader';
import ProvisionDemo from './demo/ProvisionDemo';
import PageHeader from 'Common/components/PageHeader';

const ProvisioningAutomation: React.FC = () => {
  const { featureFlags } = getBaseOutletContext();

  return (
    <Container disableGutters={true} maxWidth={false}>
      <ErrorBoundary onError={logFrontendError}>
        <Outlet context={{ featureFlags }} />
      </ErrorBoundary>
    </Container>
  );
};

export default ProvisioningAutomation;

const provisionPageHeader = (
  <PageHeader
    header="Provision Tool"
    subHeader="Manage Snowflake Resources"
    description="Manage Snowflake resource lifecycles - creating, updating, and destroying - with a templated approach to provide a well-structured information architecture."
  />
);

const aboutPage = <About pageHeader={provisionPageHeader} />;

export const ProvisioningAutomationRouter = (
  <Route element={<ProvisionHeader />}>
    <Route index element={aboutPage} />
    <Route path={'about'} index element={aboutPage} />
    <Route
      path={'demo'}
      element={
        <ResourcePageWrapper
          dataTestId="provision-tool-demo"
          pageHeader={provisionPageHeader}
          child={<ProvisionDemo />}
        />
      }
    />
  </Route>
);
