import { Button, Card, CardActions, CardContent } from '@mui/material';
import React, { ReactNode, useContext } from 'react';
import Typography from '@mui/material/Typography';
import {
  MaturityLevel,
  maturityLevelToDescription,
  ProjectMetadata,
  Status,
} from 'src/scripts/content/tools/labs/Models';
import Grid from '@mui/material/Grid2';
import Tooltip from '@mui/material/Tooltip';
import CopyToClipboard from 'copy-to-clipboard';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import { CommonContext, NotificationStatus } from 'Common/common-ui';
import LinkIcon from '@mui/icons-material/Link';
import Box from '@mui/material/Box';
import { BrandFonts } from 'Common/common-ui/common/theme';
import Stack from '@mui/material/Stack';

interface Props {
  lab: ProjectMetadata;
}

function Attribute(props: {
  name: ReactNode;
  value: ReactNode;
  valueDescription?: ReactNode;
  spacing?: number;
}): ReactNode {
  return (
    <>
      <Grid
        size={{
          xs: 12,
          sm: 5,
        }}
      >
        <Typography variant="body1" color={'primary'} fontWeight="500">
          {props.name}
        </Typography>
      </Grid>
      <Grid
        size={{
          xs: 12,
          sm: 7,
        }}
      >
        <Tooltip title={props.valueDescription} enterDelay={500}>
          <Box
            sx={{
              fontFamily: BrandFonts.Primary,
              fontWeight: 500,
            }}
          >
            {props.value}
          </Box>
        </Tooltip>
      </Grid>
    </>
  );
}
export default function LabCard(props: Props): ReactNode {
  const { lab } = props;
  const { addNotification } = useContext(CommonContext);

  const maturityLevel = lab.maturityLevel
    ? MaturityLevel[lab.maturityLevel]
    : MaturityLevel.CONCEPT;
  const status = lab.status ? Status[lab.status] : Status.ACTIVE;

  function copyGitSshUrl() {
    CopyToClipboard(lab.gitSshUrl);
    addNotification({
      message: 'Copied Git SSH URL',
      status: NotificationStatus.SUCCESS,
    });
  }

  function owners(): ReactNode {
    if (!lab.owners) {
      return <Typography>None</Typography>;
    }

    return (
      <Stack spacing={1}>
        {lab.owners.slice(0, 3).map((owner, index) => (
          <Box key={index}>{owner}</Box>
        ))}
      </Stack>
    );
  }

  return (
    <Card
      raised={true}
      sx={{
        height: '100%',
        p: 1,
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <CardContent>
        <Typography variant="h2" fontWeight={400}>
          {lab.name}
        </Typography>
        <Typography my={2}>{lab.description}</Typography>
        <Grid container spacing={1}>
          <Attribute name="Status" value={status} />
          <Attribute
            name="Maturity Level"
            value={maturityLevel}
            valueDescription={maturityLevelToDescription.get(maturityLevel)}
          />
          <Attribute
            name="Owners"
            value={owners()}
            valueDescription={lab.owners?.slice(3)?.join(', ')}
          />
        </Grid>
      </CardContent>
      <CardActions
        style={{
          display: 'flex',
          marginTop: 'auto',
        }}
      >
        <Button
          size="small"
          color="primary"
          href={lab.repositoryUrl}
          target="_blank"
          rel="noreferrer"
          startIcon={<LinkIcon />}
        >
          Repository
        </Button>
        <Button
          size="small"
          color="primary"
          onClick={copyGitSshUrl}
          startIcon={<ContentCopyIcon />}
        >
          Git SSH URL
        </Button>
      </CardActions>
    </Card>
  );
}
