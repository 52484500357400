import {
  CssBaseline,
  StyledEngineProvider,
  ThemeProvider,
} from '@mui/material';
import { handlePageAnalytics } from 'Common/analytics';
import { SnackbarProvider } from 'notistack';
import React from 'react';
import { AppBaseProps } from 'Common/common-ui';
import theme from '../common/theme';
import AppBaseInternal from './appBaseInternal';
import './styles.scss';

export const AppBase: React.FC<AppBaseProps & { children: any }> = (
  props: AppBaseProps & { children: any }
) => {
  handlePageAnalytics();

  return (
    <StyledEngineProvider injectFirst>
      {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
      <CssBaseline />
      <ThemeProvider theme={theme}>
        <SnackbarProvider
          maxSnack={3}
          autoHideDuration={3000}
          classes={{
            anchorOriginBottomCenter: 'snackbar-bottom-center',
            anchorOriginTopRight: 'snackbar-top-right',
            root: 'snackbar-root',
          }}
        >
          <AppBaseInternal>{props.children}</AppBaseInternal>
        </SnackbarProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};
