import InfoOutlined from '@mui/icons-material/InfoOutlined';
import OpenInNewOutlined from '@mui/icons-material/OpenInNewOutlined';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid2';
import Link from '@mui/material/Link';
import List from '@mui/material/List';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import * as Paths from '@toolkit/router/paths';
import { BrandColors } from 'Common/common-ui/common/theme';
import * as React from 'react';
import { Location, Link as RouterLink, useLocation } from 'react-router-dom';
import { NavOption, SubNavItem } from '../../content';
import { drawerWidth } from './LeftNav';
import ListItemButton from '@mui/material/ListItemButton';
import { useAuth0 } from '@auth0/auth0-react';

interface RouterLinkProps {
  routeLinks: NavOption;
  handleLeftNavCollapse: () => void;
  featureFlags: { [key: string]: boolean };
}

const NavLinkList: React.FC<RouterLinkProps> = ({
  routeLinks,
  handleLeftNavCollapse,
  featureFlags,
}: RouterLinkProps): React.ReactElement => {
  const { pathname }: Location = useLocation();
  const mainRouteKeys: string[] = Object.keys(routeLinks) || [];
  const [active, setActive] = React.useState<string>(Paths.Home);

  const auth0 = useAuth0();

  React.useEffect(() => {
    const links: string[] =
      pathname.split(/\//).filter((l: string) => l !== '') || [];
    if (links.length >= 2 && links[0] !== 'docs') {
      setActive(`/${links[0]}/${links[1]}`);
    } else if (links.length >= 1) {
      setActive(`/${links[0]}`);
    } else {
      setActive(Paths.Home);
    }
  }, [pathname]);

  const handleLinkClick = (param: string): void => {
    setActive(param);
    handleLeftNavCollapse();
  };

  const enableNavLink = (subLink: SubNavItem): boolean => {
    let visible = true;
    if (subLink.internal) {
      visible =
        auth0.isAuthenticated && auth0.user?.email.endsWith('@phdata.io');
    }
    if ('featureFlag' in subLink) {
      visible = visible && featureFlags[subLink?.featureFlag];
    }
    return visible;
  };

  return (
    <>
      {mainRouteKeys.map((mainKey) => (
        // Top level menu items
        <List key={mainKey} sx={{ width: drawerWidth }}>
          <Grid container direction="row" alignItems="center" sx={{ ml: 2 }}>
            <Grid>
              <Typography variant="overline">
                <Box component="div">{routeLinks[mainKey].title}</Box>
              </Typography>
            </Grid>
            {routeLinks[mainKey].info && (
              // Add the info icon with a tooltip
              <Grid sx={{ ml: 1 }}>
                <Typography>
                  <Tooltip placement="right" title={routeLinks[mainKey].info}>
                    <InfoOutlined
                      color={'info'}
                      sx={{ fontSize: 'inherit', verticalAlign: 'middle' }}
                    />
                  </Tooltip>
                </Typography>
              </Grid>
            )}
          </Grid>
          {routeLinks[mainKey].children
            .filter((subLink: SubNavItem) => enableNavLink(subLink))
            .map((subLink: SubNavItem) => (
              // Child menu items
              <Box
                component="div"
                key={subLink.title}
                bgcolor={
                  active === subLink.to ? BrandColors.LightGray : 'inherit'
                }
              >
                {enableNavLink(subLink) && (
                  <>
                    {subLink.externalURL ? (
                      <Link
                        href={subLink.to}
                        target="_blank"
                        rel="noopener noreferrer"
                        underline={'none'}
                      >
                        <ListItemButton>
                          <Grid container direction="row" alignItems="center">
                            <Grid>
                              <ListItemIcon
                                sx={{
                                  minWidth: 30,
                                  color: BrandColors.FadePrimary,
                                }}
                              >
                                {subLink.icon}
                              </ListItemIcon>
                            </Grid>
                            <Grid>
                              <ListItemText
                                primary={subLink.title}
                                primaryTypographyProps={{
                                  variant:
                                    active === subLink.to
                                      ? 'subtitle2'
                                      : 'body2',
                                }}
                              />
                            </Grid>
                            <Grid>
                              <ListItemIcon>
                                <Tooltip title={'Opens in a new tab'}>
                                  <OpenInNewOutlined
                                    sx={{
                                      fontSize: 'inherit',
                                      verticalAlign: 'middle',
                                      ml: 1,
                                    }}
                                  />
                                </Tooltip>
                              </ListItemIcon>
                            </Grid>
                          </Grid>
                        </ListItemButton>
                      </Link>
                    ) : (
                      <Link
                        component={RouterLink}
                        to={subLink.to}
                        key={subLink.title}
                        onClick={() => handleLinkClick(subLink.to)}
                        underline={'none'}
                      >
                        <ListItemButton>
                          <ListItemIcon
                            sx={{
                              minWidth: 30,
                              color:
                                active === subLink.to
                                  ? BrandColors.Primary
                                  : BrandColors.FadePrimary,
                            }}
                          >
                            {subLink.icon}
                          </ListItemIcon>
                          <ListItemText
                            primary={subLink.title}
                            primaryTypographyProps={{
                              variant:
                                active === subLink.to ? 'subtitle2' : 'body2',
                            }}
                            sx={{
                              flex: '0 auto',
                              color: subLink.deprecatedFlag
                                ? BrandColors.Gray
                                : BrandColors.Primary,
                            }}
                          />
                          {subLink.newFlag && (
                            <Typography
                              ml={1}
                              variant="caption"
                              fontWeight="600"
                              color={BrandColors.Teal}
                            >
                              New
                            </Typography>
                          )}
                          {subLink.deprecatedFlag && (
                            <Typography
                              ml={1}
                              variant="caption"
                              fontWeight="600"
                              color={BrandColors.Gray}
                            >
                              Retired
                            </Typography>
                          )}
                        </ListItemButton>
                      </Link>
                    )}
                  </>
                )}
              </Box>
            ))}
        </List>
      ))}
    </>
  );
};

export default NavLinkList;
