import Container from '@mui/material/Container';
import { ErrorBoundary } from 'Common/common-ui';
import { logFrontendError } from 'Common/services/api';
import React, { ReactNode } from 'react';
import { Outlet, Route } from 'react-router-dom';
import Dashboard from 'src/scripts/content/tools/labs/Labs';
import Labs from 'src/scripts/content/tools/labs/Labs';

function LabsWrapper(): ReactNode {
  return (
    <Container maxWidth={false} sx={{ p: '0px !important', height: '100%' }}>
      <ErrorBoundary onError={logFrontendError}>
        <Outlet />
      </ErrorBoundary>
    </Container>
  );
}

export default LabsWrapper;

export const LabsRouter = (
  <Route>
    <Route index element={<Labs />} />
    <Route path={':name'} element={<Dashboard />} />
  </Route>
);
