import { Box, Typography } from '@mui/material';
import { BrandColors } from 'Common/common-ui/common/theme';
import React, { ReactNode } from 'react';
import ToolHeader from 'src/resources/images/tool-page-header.jpg';

interface HeaderProps {
  header: string;
  subHeader?: string;
  description?: ReactNode;
  descriptionWidth?: number | string;
  height?: number | string;
  backgroundImage?: string;
}

export default function PageHeader(props: HeaderProps) {
  const height = props.height || '250px';
  const descriptionWidth = props.descriptionWidth || '60%';

  const backgroundImageUrl = props.backgroundImage || ToolHeader;

  return (
    <div
      style={{
        backgroundImage: `url(${backgroundImageUrl})`,
        backgroundSize: 'cover',
        display: 'flex',
        justifyContent: 'left',
        alignItems: 'center',
        alignContent: 'center',
        height: height,
        width: '100% !important',
      }}
    >
      <Box sx={{ pl: 5, ml: 3 }}>
        <Typography
          sx={{
            fontSize: '2.25em',
            fontWeight: 500,
            color: BrandColors.White,
            mb: 0.5,
          }}
        >
          {props.header}
        </Typography>
        <Typography
          variant="h2"
          sx={{
            mb: 2,
            color: BrandColors.Mint,
            fontWeight: 500,
            fontSize: '1.7em',
          }}
        >
          {props.subHeader}
        </Typography>
        <Box sx={{ color: BrandColors.White, width: descriptionWidth }}>
          {props.description}
        </Box>
      </Box>
    </div>
  );
}
