import { RedirectLoginOptions, useAuth0 } from '@auth0/auth0-react';
import { Alert, Box } from '@mui/material';
import Typography from '@mui/material/Typography';
import * as Paths from '@toolkit/router/paths';
import { LoadingIndicator } from '@toolkit/common';
import * as React from 'react';
import { Location, useLocation } from 'react-router-dom';
import AuthTokenGenerator from './components/AuthTokenGenerator';
import DownloadAuthorizer from './components/DownloadAuthorizer';
import Licenses from './components/Licenses';
import TealLink from 'Common/components/TealLink';

function CodeSpan(props: { children: React.ReactNode }) {
  return (
    <Box
      component="span"
      sx={{
        display: 'inline',
        background: '#e0e0e0',
        borderRadius: 1,
        padding: '3px',
      }}
    >
      {props.children}
    </Box>
  );
}

export default function ToolAccess() {
  const { isAuthenticated, loginWithRedirect } = useAuth0();
  const location: Location = useLocation();

  if (!isAuthenticated) {
    const options: RedirectLoginOptions = {
      appState: { returnTo: location.pathname },
    };
    loginWithRedirect(options);
    return (
      <LoadingIndicator
        fullScreen={true}
        title="Redirecting to login page..."
      />
    );
  }

  return (
    <Box
      sx={{
        my: 1,
        px: 2,
      }}
    >
      <Box my={1}>
        <Typography variant="h3" pb={3} fontWeight="500">
          Download Tools
        </Typography>
        <Typography variant="body1" gutterBottom>
          Select the tool you would like to download and retrieve the URL.
        </Typography>
        <Typography variant="body1" gutterBottom>
          Some tools are are public, but others are private. If the URL starts
          with <CodeSpan>https://repo.phdata.io/public</CodeSpan>, then it is a
          public tool. If the URL does not have <CodeSpan>public</CodeSpan>{' '}
          after the host, then it is a private tool, and uses an entitlement
          token made specifically for you.
        </Typography>
        <Alert variant="outlined" severity="warning">
          Treat download URLs with tokens like passwords because they are tied
          to your Toolkit account
        </Alert>
        <DownloadAuthorizer />
      </Box>
      <Box my={1}>
        <Typography variant="h3" pb={3} fontWeight="500">
          Auth Tokens
        </Typography>
        <Typography variant="body1" gutterBottom>
          Auth tokens are required to use certain tools, like the{' '}
          <TealLink to={Paths.ToolkitCliDocs}>toolkit-cli</TealLink>.
        </Typography>
        <Typography variant="body1" gutterBottom>
          Generate an auth token and follow the instructions in the tool's
          documentation to use the tool with the auth token.
        </Typography>
        <Alert variant="outlined" severity="warning">
          Treat these tokens like passwords because they are tied to your
          Toolkit account
        </Alert>
        <AuthTokenGenerator />
      </Box>
      <Box my={1}>
        <Licenses />
      </Box>
    </Box>
  );
}
