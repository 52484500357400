import React from 'react';
import { createRoot } from 'react-dom/client';
import { QueryClient, QueryClientProvider } from 'react-query';
import reportWebVitals from './reportWebVitals';
import AppRouter from './scripts/router/AppRouter';
import './styles/_styles.scss';
import { Auth0Provider } from '@auth0/auth0-react';
import { getAppConfig } from 'Common/common-ui';
import { skipRedirectUrls } from 'Common/common-ui/common/util';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
    },
  },
});

const container = document.querySelector('#root');
const root = createRoot(container);

root.render(
  <QueryClientProvider client={queryClient}>
    <Auth0Provider
      domain={getAppConfig().auth0.domain}
      clientId={getAppConfig().auth0.clientId}
      authorizationParams={{
        redirect_uri: window.location.origin,
      }}
      skipRedirectCallback={skipRedirectUrls.includes(window.location.pathname)}
      cacheLocation="localstorage"
      useRefreshTokens={true}
    >
      <AppRouter />
    </Auth0Provider>
  </QueryClientProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
