import NoteAltOutlined from '@mui/icons-material/NoteAltOutlined';
import { Box, Chip, Divider, Typography } from '@mui/material';
import * as Paths from '@toolkit/router/paths';
import { CommonContext } from 'Common/common-ui';
import TealLink from 'Common/components/TealLink';
import { AnalyticsTechnology } from 'Common/enums';
import { AnalyticsLibraryProps } from 'Common/interfaces';
import { getDashboards } from 'Common/services/api';
import React from 'react';
import { NavigateFunction, useNavigate, useParams } from 'react-router-dom';
import PowerBIPage from './technologies/PowerBI';
import Sigma from './technologies/Sigma';

const TableauPage = React.lazy(() => import('./technologies/tableau/Tableau'));

const AnalyticsLibrary: React.FC = () => {
  const navigate: NavigateFunction = useNavigate();
  const descRef = React.useRef(null);
  const {
    state: { dashboards },
  } = React.useContext(CommonContext);
  const [dashboard, setDashboard] = React.useState<AnalyticsLibraryProps>();
  const params = useParams<{ id: string }>();

  React.useEffect(() => {
    const dashId = params.id;
    (async function () {
      let dashboardList: AnalyticsLibraryProps[] = dashboards;
      if (!dashboardList.length) {
        dashboardList = await getDashboardsList();
      }
      if (dashId?.trim() && dashboardList.length) {
        const dashboard = dashboardList.filter((d) => d.id === dashId)[0];
        if (dashboard?.vizUrl) {
          setDashboard(dashboard);
        } else {
          navigate(`${Paths.AnalyticsLibrary}`, { replace: true });
        }
      } else {
        navigate(`${Paths.AnalyticsLibrary}`, { replace: true });
      }
    })();
  }, [params.id]);

  async function getDashboardsList(): Promise<AnalyticsLibraryProps[]> {
    const response = await getDashboards();
    return response.dashboards;
  }

  function getTags() {
    const tags =
      (dashboard && [dashboard?.technology, ...dashboard?.labels]) || [];
    return (
      <Box display="inline-flex">
        {tags.length &&
          tags.map((v: string, ind: number) => (
            <Chip
              sx={{ mr: 1 }}
              size={'small'}
              key={ind}
              color={'primary'}
              label={v}
            />
          ))}
      </Box>
    );
  }

  return (
    <Box p={5} mb={1} position="relative" maxWidth={'xl'}>
      <Typography variant="h1" fontWeight="500" align={'center'} mt={1}>
        {dashboard?.title}
      </Typography>
      <Divider sx={{ mt: 1, mb: 1 }} />
      <Box mb={1}>
        {dashboard?.description && (
          <Typography ref={descRef} variant="body1">
            {dashboard?.description}
          </Typography>
        )}
        <Typography variant="body1" mt={0.5}>
          To learn more, see the full phData
          <TealLink sx={{ ml: 1 }} to={dashboard?.blogUrl} target="_blank">
            <Box component="span" position="relative" top={5}>
              <NoteAltOutlined />
            </Box>
            Blog Post
          </TealLink>
          .
        </Typography>
        <Box mt={0.5}>{getTags()}</Box>
      </Box>
      {dashboard && dashboard?.technology === AnalyticsTechnology.TABLEAU && (
        <TableauPage dashboard={dashboard} />
      )}
      {dashboard && dashboard?.technology === AnalyticsTechnology.POWER_BI && (
        <PowerBIPage dashboard={dashboard} />
      )}
      {dashboard && dashboard?.technology === AnalyticsTechnology.SIGMA && (
        <Sigma dashboard={dashboard} />
      )}
    </Box>
  );
};

export default AnalyticsLibrary;
