import {
  AccountTreeOutlined,
  AnalyticsOutlined,
  AutoFixHighOutlined,
  BookOutlined,
  CodeOutlined,
  DoubleArrowOutlined,
  DownloadOutlined,
  HelpOutline,
  HomeOutlined,
  LibraryBooksOutlined,
  LockOutlined,
  MenuBookOutlined,
  PsychologyOutlined,
  SchoolOutlined,
  ScienceOutlined,
  TrackChangesOutlined,
  UpdateOutlined,
} from '@mui/icons-material';
import AnalyticsLibrary, {
  AnalyticsLibraryRouter,
} from 'AnalyticsLibrary/AnalyticsLibraryRouting';
import {
  sqlMorphLoader,
  SQLMorphRouter,
} from 'Sqlmorph/scripts/SQLMorphRouting';
import * as React from 'react';
import {
  ActionFunction,
  LoaderFunction,
  ShouldRevalidateFunction,
} from 'react-router-dom';
import * as paths from '../router/paths';
import SQLMorph from 'Sqlmorph/SQLMorph';
import Home from './general/Home';
import Updates from './general/updates/Updates';
import Documentation, {
  documentationRouter,
} from './resources/documentation/Documentation';
import Support from './resources/support';
import ProvisioningAutomation, {
  ProvisioningAutomationRouter,
} from './tools/provision-tool/ProvisioningAutomation';
import ToolAccess from './tools/tool-access/ToolAccess';
import Train, { TrainingRouter } from './resources/training/TrainingRouter';
import { BrandColors } from 'Common/common-ui/common/theme';
import ToolsWrapper from './tools/ToolsWrapper';
import AboutToolWrapper from './tools/AboutToolWrapper';
import ImageGrid from 'Common/components/ImageGrid';
import { ImageArray as AdvisorImageArray } from './tools/advisor-tool/ImageGridArray';
import { ImageArray as AccessImageArray } from './tools/access-tool/ImageGridArray';
import { ImageArray as DataGenImageArray } from './tools/data-generation-tool/ImageGridArray';
import { ImageArray as DataSourceImageArray } from './tools/data-source-automation/ImageGridArray';
import { CardMedia } from '@mui/material';
import CdopsmonBg from 'src/resources/images/cdopsmon.png';
import ResourcePageWrapper from './ResourcePageWrapper';
import LabsWrapper, {
  LabsRouter,
} from 'src/scripts/content/tools/labs/LabsRouting';
import PageHeader from 'Common/components/PageHeader';
import * as Paths from '@toolkit/router/paths';
import TealLink from 'Common/components/TealLink';

const iconColor = BrandColors.Primary;

export interface SubNavItem {
  title: string;
  icon: React.ReactElement;
  to: string;
  children?: any;
  externalURL?: boolean;
  component: React.ReactNode;
  featureFlag?: string;
  newFlag?: boolean;
  deprecatedFlag?: boolean;
  loader?: LoaderFunction;
  action?: ActionFunction;
  shouldRevalidate?: ShouldRevalidateFunction;
  internal?: boolean;
}

export interface MainNavItem {
  title: string;
  children: SubNavItem[];
}

export interface NavOption {
  general: MainNavItem;
  tools: MainNavItem;
  resources: MainNavItem;
}

export const navOptions: NavOption = {
  general: {
    title: 'General',
    children: [
      {
        title: 'Home',
        icon: <HomeOutlined sx={{ color: iconColor }} />,
        to: paths.Home,
        component: <Home />,
      },
      {
        title: 'Updates',
        icon: <UpdateOutlined sx={{ color: iconColor }} />,
        to: paths.Updates,
        component: <Updates />,
      },
    ],
  },
  tools: {
    title: 'Tools',
    children: [
      {
        title: 'Advisor Tool',
        icon: <PsychologyOutlined sx={{ color: iconColor }} />,
        to: paths.AdvisorTool,
        component: (
          <AboutToolWrapper
            pageHeader={
              <PageHeader
                header="Advisor Tool"
                subHeader="Optimize & Secure Snowflake"
                description="Quickly and easily identify opportunities to improve the configuration, security, performance, and efficiency of your Snowflake environment."
              />
            }
            leftChild={<ToolsWrapper />}
            rightChild={
              <ImageGrid
                imageItems={AdvisorImageArray}
                rowHeight={175}
                cols={4}
                width={'95%'}
                height={'auto'}
              />
            }
            dataTestId={'advisor-tool'}
          />
        ),
      },
      {
        title: 'Provision Tool',
        icon: <AccountTreeOutlined sx={{ color: iconColor }} />,
        to: paths.ProvisionTool,
        component: <ProvisioningAutomation />,
        children: ProvisioningAutomationRouter,
      },
      {
        title: 'Access Tool',
        icon: <LockOutlined sx={{ color: iconColor }} />,
        to: paths.AccessTool,
        component: (
          <AboutToolWrapper
            pageHeader={
              <PageHeader
                header="Access Tool"
                subHeader="Visualize Roles & Privileges"
                description="Powerful visualization and reporting that enables Snowflake data access, auditing, and troubleshooting."
              />
            }
            leftChild={<ToolsWrapper />}
            rightChild={
              <ImageGrid
                imageItems={AccessImageArray}
                rowHeight={175}
                cols={4}
                width={'100%'}
                height={'auto'}
              />
            }
            dataTestId={'access-tool'}
          />
        ),
      },
      {
        title: 'Data Generation Tool',
        icon: <AutoFixHighOutlined sx={{ color: iconColor }} />,
        to: paths.DataGenerationTool,
        component: (
          <AboutToolWrapper
            pageHeader={
              <PageHeader
                header="Data Generation Tool"
                subHeader="Ultra-Realistic Relational Synthetic Data Generator"
                description="Generate realistic synthetic data for testing, development, and demonstrations."
              />
            }
            leftChild={<ToolsWrapper />}
            rightChild={
              <ImageGrid
                imageItems={DataGenImageArray}
                rowHeight={175}
                cols={4}
                width={'100%'}
                height={'auto'}
              />
            }
            dataTestId={'data-generation-tool'}
          />
        ),
        newFlag: true,
      },
      {
        title: 'Data Source Tool',
        icon: <DoubleArrowOutlined sx={{ color: iconColor }} />,
        to: paths.DataSourceTool,
        component: (
          <AboutToolWrapper
            pageHeader={
              <PageHeader
                header="Data Source Tool"
                subHeader="Analyze Data Source Metadata"
                description="A multipurpose tool that collects, compares, analyzes, and acts on data source metadata and profile metrics."
              />
            }
            leftChild={<ToolsWrapper />}
            rightChild={
              <ImageGrid
                imageItems={DataSourceImageArray}
                rowHeight={175}
                cols={4}
                width={'95%'}
                height={'auto'}
              />
            }
            dataTestId={'dataSource-automation'}
          />
        ),
      },
      {
        title: 'SQL Translation',
        icon: <CodeOutlined sx={{ color: iconColor }} />,
        to: paths.SqlTranslation,
        component: <SQLMorph />,
        children: SQLMorphRouter,
        loader: sqlMorphLoader,
        shouldRevalidate: () => false,
      },
      {
        title: 'Analytics Library',
        icon: <AnalyticsOutlined sx={{ color: iconColor }} />,
        to: paths.AnalyticsLibrary,
        component: <AnalyticsLibrary />,
        children: AnalyticsLibraryRouter,
      },
      {
        title: 'Platform Monitoring',
        icon: <TrackChangesOutlined sx={{ color: iconColor }} />,
        to: paths.PlatformMonitoring,
        component: (
          <AboutToolWrapper
            pageHeader={
              <PageHeader
                header="Platform Monitoring"
                subHeader="Simple Monitoring for your Modern Data Stack"
                description="Easily collect, evaluate, and alert on the state of your data platform to ensure that everything continues to run as it should."
              />
            }
            leftChild={<ToolsWrapper />}
            rightChild={
              <CardMedia
                component="img"
                alt="Image for cdopsmon"
                src={CdopsmonBg}
              />
            }
            dataTestId={'platform-monitoring'}
          />
        ),
      },
      {
        title: 'phData Labs',
        icon: <ScienceOutlined sx={{ color: iconColor }} />,
        to: paths.Labs,
        component: <LabsWrapper />,
        children: LabsRouter,
        newFlag: true,
        internal: true,
      },
    ],
  },
  resources: {
    title: 'Resources',
    children: [
      {
        title: 'Documentation',
        icon: <MenuBookOutlined sx={{ color: iconColor }} />,
        to: paths.Documentation,
        component: <Documentation />,
        children: documentationRouter,
      },
      {
        title: 'Tool Access',
        icon: <DownloadOutlined sx={{ color: iconColor }} />,
        to: paths.ToolAccess,
        component: (
          <ResourcePageWrapper
            pageHeader={
              <PageHeader
                header="Tool Access"
                subHeader="Download Tools and Generate Auth Tokens"
                height={200}
              />
            }
            child={<ToolAccess />}
            dataTestId="tool-access"
          />
        ),
      },
      {
        title: 'Training',
        icon: <SchoolOutlined sx={{ color: iconColor }} />,
        to: paths.SampleTraining,
        component: (
          <ResourcePageWrapper
            pageHeader={
              <PageHeader
                header="Training"
                subHeader="Empower your Team"
                description={
                  <>
                    Empower your team to build successful data solutions with
                    our data coach courses. Explore our free sample courses on
                    this page or learn more about our training and enablement
                    services{' '}
                    <TealLink
                      rel="noopener"
                      target="_blank"
                      to={Paths.TrainingEnablement}
                    >
                      here
                    </TealLink>
                    .
                  </>
                }
              />
            }
            child={<Train />}
            dataTestId="train"
          />
        ),
        children: TrainingRouter,
      },
      {
        title: 'Blog',
        icon: <LibraryBooksOutlined sx={{ color: iconColor }} />,
        to: paths.Blog,
        externalURL: true,
        component: null,
      },
      {
        title: 'About phData',
        icon: <BookOutlined sx={{ color: iconColor }} />,
        to: paths.About,
        externalURL: true,
        component: null,
      },
      {
        title: 'Support',
        icon: <HelpOutline sx={{ color: iconColor }} />,
        to: paths.Support,
        component: (
          <ResourcePageWrapper
            pageHeader={
              <PageHeader
                header="Support"
                subHeader="We Are Always Here to Help"
                height={200}
              />
            }
            child={<Support />}
            dataTestId="support"
          />
        ),
      },
    ],
  },
};
